import React from "react";
import { LayoutWrapper } from "../components/LayoutWrapper";
import { Body } from "../components/LayoutPrimitives";

const NotFound = () => {
  return (
    <LayoutWrapper>
      <Body>404 Not Found</Body>
    </LayoutWrapper>
  );
};

export default NotFound;
